export type IdentificationIntent = 'registration' | 'login' | 'update';

export const identificationService = {
  getIdentificationUrl: (intent: IdentificationIntent, returnUrl: string) => {
    const params = new URLSearchParams();
    params.set('intent', intent);
    params.set('returnUrl', returnUrl);

    return `/tunnistaudu?${params.toString()}`;
  },
};
