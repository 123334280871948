'use client';

import { startTransition, useState } from 'react';
import { LoginModal } from '@/components/Auth/LoginModal';
import { gtmService } from '@/util/gtm-service';

export const useLoginModal = (defaultOpen = false, onSuccess = () => {}) => {
  const [isModalOpen, setIsModalOpen] = useState(defaultOpen);

  function Modal() {
    return isModalOpen ? (
      <LoginModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} onSuccess={onSuccess} />
    ) : null;
  }

  const openLoginModal = () => {
    // Avoid blocking the UI while opening the modal.
    startTransition(() => {
      setIsModalOpen(true);
    });
    gtmService.event('login:open');
  };

  return {
    LoginModal: Modal,
    openLoginModal,
    closeLoginModal: () => setIsModalOpen(false),
  };
};
