import { createContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { debounce } from 'lodash-es';
import { useToast } from '@mezzoforte/forge';
import { env } from 'next-runtime-env';
import { HermesEntryAPIResponse } from '@/types/ApiResponse';
import { addVatToPrice } from '@/util/price';
import { HermesEntry } from '@/types/ListEntry';
import { useClientRendering } from '@/hooks/useClientRendering';

const DingInterval = 30000;
const MaxLatencyAllowed = 10000;
const ErrorRecoveryTimeout = 3000; // If we recover from an error in this time (ms), don't display error toast.

export interface HermesEvents {
  connect: [];
  update: [entry: HermesEntryAPIResponse];
  dong: [dongResponse: { ts: number }];
  skew: [skew: number];
  error: [];
  connect_error: [];
  'subscribe:error': [error: string];
  reconnect: [];
}

interface SubscriptionItem {
  id: number;
  vatPerc: number;
}
type SubscriptionFn = (entries: SubscriptionItem[]) => void;
type ListenerFn = <TEvent extends keyof HermesEvents>(
  event: TEvent,
  fn: (...params: HermesEvents[TEvent]) => void
) => void;

export interface HermesClient {
  readonly subscribe: SubscriptionFn;
  readonly unsubscribe: SubscriptionFn;
  readonly fetchUpdate: (entryId: number) => void;
  readonly getSkew: () => number;
  readonly on: ListenerFn;
  readonly off: ListenerFn;
  readonly entries: Map<number, HermesEntry>;
}

const createHermesClient = (hermesUrl: string): HermesClient => {
  const socket = io(hermesUrl, {
    transports: ['websocket', 'polling'],
  });
  const subscriptionCounts = new Map<number, { count: number; subscribed: boolean }>();
  const vatPercs = new Map<number, number>();
  const entries = new Map<number, HermesEntry>();
  let skew = 0;

  function updateSubscriptionCounts(ids: number[], operation: (count: number) => number) {
    ids.forEach((id) => {
      const item = subscriptionCounts.get(id);
      if (!item) {
        subscriptionCounts.set(id, {
          count: operation(0),
          subscribed: false,
        });
      } else {
        subscriptionCounts.set(id, {
          count: operation(item.count),
          subscribed: item.subscribed,
        });
      }
    });
  }

  socket.on('connect', () => {
    setInterval(() => {
      if (socket.connected) {
        socket.emit('ding', { ts: new Date().getTime() });
      }
    }, DingInterval);
    // Hermes server expects timestamp to be in **seconds** since unix epoch.
    socket.emit('skew', { ts: Math.round(new Date().getTime() / 1000) });
  });

  socket.on('reconnect', () => {
    const ids = [...subscriptionCounts.entries()].map(([id]) => id);
    socket.emit('subscribe', { ids });
  });

  socket.on('dong', ({ ts }: { ts: number }) => {
    if (Date.now() - ts > MaxLatencyAllowed) {
      console.error('socket error!');
    }
  });

  socket.on('skew', (newSkew: number) => {
    skew = newSkew;
  });

  socket.on('update', (entry: HermesEntryAPIResponse) => {
    const vatPerc = vatPercs.get(entry.id) ?? 0;
    entries.set(entry.id, {
      ...entry,
      bidderCount: entry.bids.reduce((max, bid) => Math.max(max, bid.bidder), 0),
      bids: entry.bids.map((bid) => ({
        ...bid,
        amount: addVatToPrice({ amount: bid.amount }, vatPerc),
        date: new Date(bid.date),
      })),
      highestBid: entry.bids.at(0)?.amount ?? null,
    });
  });

  // Periodically unsubscribe from all entries that haven't had listeners for a while (1min)
  setInterval(() => {
    const oldItems = [...subscriptionCounts].filter(([_, { count }]) => count <= 0);
    if (oldItems.length) {
      socket.emit('unsubscribe', { ids: oldItems.map(([id]) => id) });
      oldItems.forEach(([id]) => subscriptionCounts.delete(id));
    }
  }, 1000 * 60);

  // Debounced subscribe event, useful when multiple components mount and subscribe at the same time
  const emitSubscribe = debounce(() => {
    const ids = Array.from(subscriptionCounts.entries())
      .filter(([_, { subscribed }]) => !subscribed)
      .map(([id]) => id);

    if (ids.length) socket.emit('subscribe', { ids });

    // Mark all subscribed entries
    subscriptionCounts.forEach((sc, id) =>
      subscriptionCounts.set(id, {
        ...sc,
        subscribed: true,
      })
    );
  }, 0);

  return {
    subscribe(entries: { id: number; vatPerc: number }[]) {
      updateSubscriptionCounts(
        entries.map((e) => e.id),
        (count) => count + 1
      );
      entries.forEach((e) => vatPercs.set(e.id, e.vatPerc));
      emitSubscribe();
    },
    unsubscribe(entries) {
      updateSubscriptionCounts(
        entries.map((e) => e.id),
        (count) => count - 1
      );
    },
    fetchUpdate: (entryId: number) => socket.emit('fetch', { ids: [entryId] }),
    getSkew: () => skew,
    on: socket.on.bind(socket),
    off: socket.off.bind(socket),
    entries,
  };
};

export const HermesClientContext = createContext<HermesClient | null>(null);

export function HermesClientProvider({ children }: React.PropsWithChildren) {
  const isClient = useClientRendering();
  const { playToast, dismissToast } = useToast();
  const toastTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errorToast = useRef<string | number | null>(null);
  const [client, setClient] = useState<HermesClient | null>(null);

  // Socket.io clients must only be initialized on the client to avoid memory leaks.
  useEffect(() => {
    if (!isClient || client !== null) {
      return;
    }

    const newClient = createHermesClient(env('NEXT_PUBLIC_HERMES_URL') ?? '');

    const onHermesError = () => {
      toastTimeout.current = setTimeout(() => {
        if (errorToast.current === null) {
          errorToast.current = playToast(
            'Nettiyhteydessä ongelmia',
            'Huutokauppojen tiedot eivät päivity. Kokeile ladata sivu uudelleen.',
            {
              toastId: 'hermes-error',
              variant: 'danger',
              closeManually: true,
            }
          );
        }
      }, ErrorRecoveryTimeout);
    };

    const onHermesReconnect = () => {
      if (errorToast.current !== null) dismissToast(errorToast.current);
      if (toastTimeout.current !== null) clearTimeout(toastTimeout.current);
      errorToast.current = null;
    };

    newClient.on('error', onHermesError);
    newClient.on('connect_error', onHermesError);
    newClient.on('subscribe:error', onHermesError);
    newClient.on('reconnect', onHermesReconnect);

    setClient(newClient);
  }, [isClient, client, playToast, dismissToast]);

  return <HermesClientContext.Provider value={client}>{children}</HermesClientContext.Provider>;
}
