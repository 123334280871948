import React from 'react';
import { Box, TextInput, Button, PasswordInput } from '@mezzoforte/forge';
import type { LoginAPIRequest } from '@/types/ApiRequest';

export interface LoginFormProps {
  onSubmit: (credentials: LoginAPIRequest) => void;
  isPending: boolean;
  isError: boolean;
}

export const LoginForm = React.forwardRef<HTMLInputElement, LoginFormProps>(({ onSubmit, isPending, isError }, ref) => {
  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = Object.fromEntries(formData.entries()) as unknown as LoginAPIRequest;
    onSubmit(credentials);
  };

  return (
    <Box as="form" onSubmit={submitLogin as (event: unknown) => void} data-test="login-form">
      <TextInput
        ref={ref}
        data-test="username-field"
        name="username"
        type="email"
        label="Sähköpostiosoite"
        autoComplete="username"
        autoFocus
        isRequired
        isDisabled={isPending}
        isInvalid={isError}
      />
      <Box mt={3}>
        <PasswordInput
          data-test="password-field"
          name="password"
          label="Salasana"
          autoComplete="current-password"
          isRequired
          isDisabled={isPending}
          isInvalid={isError}
        />
      </Box>
      <Button mt={4} type="submit" variant="highlight-hero" width="100%" isLoading={isPending} data-test="login-submit">
        Kirjaudu
      </Button>
    </Box>
  );
});
