// Google Tag Manager. Note that this service is duplicated in huutokaupat.

import { env } from 'next-runtime-env';
import { OwnInfoAPIResponse } from '@/types/ApiResponse';

// This is effectively a list of all event names that we are using.
// Recommended events are official GA4 recommended events: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
export type RecommendedEventName = Extract<
  'login' | 'sign_up' | 'search' | 'select_content' | 'share',
  Gtag.EventNames
>;
// Some recommended events require `ecommerce` payloads: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
export type RecommendedEcommerceEventName = Extract<
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'purchase',
  Gtag.EventNames
>;
// These are custom events created by us
export type CustomEventName =
  | 'login:open'
  | 'identification:start'
  | 'identification:success'
  | 'saved_search:add'
  | 'apply_for_company_account_form:submit'
  | 'auction_form:start'
  | 'auction_form:exit'
  | 'consumer_auction_form:start'
  | 'consumer_auction_form:submit'
  | 'consumer_auction_form:exit'
  | 'consumer_auction_form:open_preview'
  | 'consumer_auction_form:close_preview'
  | 'auction:open_bid_modal'
  | 'auction:close_bid_modal'
  | 'auction:bid_too_high'
  | 'auction:apply_for_financing'
  | 'bid:approved'
  | 'bid:rejected'
  | 'return_form:open'
  | 'return_form:submit';
// These are custom events created by us, but with forced `ecommerce` payloads
export type CustomEcommerceEventName = 'auction_form:submit' | 'auction:bid' | 'auction:activate_bidding_bot';

export type CustomEventParams = Record<Lowercase<string>, unknown>;

// @see https://developers.google.com/analytics/devguides/collection/ga4/item-scoped-ecommerce
export interface CustomEcommerceItemParams {
  readonly item_category_id?: number;
  readonly item_category2_id?: number;
}

function getDataLayer() {
  window.dataLayer = window.dataLayer || [];

  if (!env('NEXT_PUBLIC_GTM_ID')) {
    return {
      push(data: unknown) {
        console.info('dataLayer.push', data);
      },
    };
  }

  return window.dataLayer;
}

// gtag should only be used for consent management, as our analytics is GTM based.
// eslint-disable-next-line func-names
const gtag: Gtag.Gtag = function () {
  getDataLayer().push(arguments); // eslint-disable-line prefer-rest-params -- gtag requires IArguments as parameters
};

export function createEcommerceItem({
  id,
  name,
  company,
  brand,
  category,
  parentCategory,
  location,
  priceWithoutShippingOrTax,
  index,
}: {
  readonly id: string;
  readonly name: string;
  readonly company: string;
  readonly brand?: string;
  readonly category: { readonly id: number; readonly name: string };
  readonly parentCategory?: { readonly id: number; readonly name: string };
  readonly location: string;
  readonly priceWithoutShippingOrTax: number;
  readonly index?: number;
}): Gtag.Item & CustomEcommerceItemParams {
  return {
    item_id: id,
    item_name: name,
    affiliation: company,
    item_brand: brand,
    item_category: parentCategory?.name,
    item_category2: category.name,
    location_id: location,
    price: priceWithoutShippingOrTax,
    index,

    // Custom category ids for ad targeting.
    item_category_id: parentCategory?.id,
    item_category2_id: category.id,
  };
}

function recommendedEvent(
  event: RecommendedEventName,
  params: Gtag.EventParams & Gtag.CustomParams,
  custom: CustomEventParams = {}
) {
  getDataLayer().push({ event, ...params, custom });
}

function recommendedEcommerceEvent(
  event: RecommendedEcommerceEventName,
  params: Gtag.EventParams & Gtag.CustomParams,
  custom: CustomEventParams = {}
) {
  getDataLayer().push({ ecommerce: null });
  getDataLayer().push({ event, ecommerce: params, custom });
}

function customEcommerceEvent(
  event: CustomEcommerceEventName,
  params: Gtag.EventParams & Gtag.CustomParams,
  custom: CustomEventParams = {}
) {
  getDataLayer().push({ ecommerce: null });
  getDataLayer().push({ event, ecommerce: params, custom });
}

export const gtmService = {
  init() {
    const gtmCode = env('NEXT_PUBLIC_GTM_ID');
    if (!gtmCode) {
      return;
    }

    // GTM is already loaded
    if (document.getElementById('google-tag-manager-script') !== null) {
      return;
    }

    // @see https://support.cookiebot.com/hc/en-us/articles/360016047000-Implementing-Google-consent-mode
    gtag('consent', 'default', {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    });
    gtag('set', 'ads_data_redaction', true);
    gtag('set', 'url_passthrough', false);

    getDataLayer().push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });

    // Load Tag Manager script.
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmCode}`;
    scriptElement.id = 'google-tag-manager-script';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(scriptElement, firstScript);
  },

  event(event: CustomEventName, params: CustomEventParams = {}) {
    getDataLayer().push({ event, ...params });
  },

  sendUserData(currentUser: OwnInfoAPIResponse) {
    if (!currentUser.isAuthenticated) {
      getDataLayer().push({ event: 'user_data', user: { logged_in: false } });
      return;
    }

    const { user, companies } = currentUser;

    if (user.preventDataCollection || user.isAdmin || user.isAccounting) {
      return;
    }

    getDataLayer().push({ event: 'user_data', user: { logged_in: true, id: user.uuid, company: companies[0] } });
  },

  recommended: {
    login(method: 'Password' | 'Identification', custom: CustomEventParams = {}) {
      recommendedEvent('login', { method }, custom);
    },

    signUp(method: 'Form', custom: CustomEventParams = {}) {
      recommendedEvent('sign_up', { method }, custom);
    },

    search(term: string, custom: CustomEventParams = {}) {
      recommendedEvent('search', { search_term: term }, custom);
    },

    selectContent(type: 'cta', id: string, custom: CustomEventParams = {}) {
      recommendedEvent('select_content', { content_type: type, content_id: id }, custom);
    },

    share(method: 'WhatsApp' | 'Facebook', type: 'auction', id: string, custom: CustomEventParams = {}) {
      recommendedEvent(
        'share',
        {
          method,
          content_type: type,
          item_id: id,
        },
        custom
      );
    },
  },

  recommendedEcommerce: {
    viewItemList(listId: Lowercase<string>, listName: string, items: Gtag.Item[], custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'view_item_list',
        {
          item_list_id: listId,
          item_list_name: listName,
          items,
        },
        custom
      );
    },

    selectItem(listId: Lowercase<string>, listName: string, item: Gtag.Item, custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'select_item',
        {
          item_list_id: listId,
          item_list_name: listName,
          items: [item],
        },
        custom
      );
    },

    viewItem(item: Gtag.Item, custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'view_item',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },

    addToWishlist(item: Gtag.Item, custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'add_to_wishlist',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },

    beginCheckout(item: Gtag.Item, custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'begin_checkout',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },

    addPaymentInfo(item: Gtag.Item, bank: string, custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'add_payment_info',
        {
          currency: 'EUR',
          value: Number(item.price),
          payment_type: bank,
          items: [item],
        },
        custom
      );
    },

    addShippingInfo(item: Gtag.Item, tier: 'Retrieval' | 'Delivery', custom: CustomEventParams = {}) {
      recommendedEcommerceEvent(
        'add_shipping_info',
        {
          currency: 'EUR',
          value: Number(item.price),
          shipping_tier: tier,
          items: [item],
        },
        custom
      );
    },

    purchase(
      id: string,
      revenue: number,
      billableValue: number,
      shipping: number,
      item: Gtag.Item,
      custom: CustomEventParams = {}
    ) {
      recommendedEcommerceEvent(
        'purchase',
        {
          currency: 'EUR',
          transaction_id: id,
          value: revenue, // Price + tax + shipping
          shipping,
          tax: billableValue, // Tax field is used to house Huutokaupat cut of the sale
          items: [item],
        },
        custom
      );
    },
  },

  // Custom events with ecommerce payloads.
  customEcommerce: {
    auctionFormSubmit(item: Gtag.Item, custom: CustomEventParams = {}) {
      customEcommerceEvent(
        'auction_form:submit',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },

    bid(item: Gtag.Item, custom: CustomEventParams = {}) {
      customEcommerceEvent(
        'auction:bid',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },

    activateBiddingBot(item: Gtag.Item, custom: CustomEventParams = {}) {
      customEcommerceEvent(
        'auction:activate_bidding_bot',
        {
          currency: 'EUR',
          value: Number(item.price),
          items: [item],
        },
        custom
      );
    },
  },
};
