import { useCallback, useMemo } from 'react';
import axios, { Axios } from 'axios';
import { env } from 'next-runtime-env';

interface UseApi {
  readonly apiClient: Axios;
  readonly apiFetch: (url: string, method?: string) => Promise<Response>;
}

export function useApi(): UseApi {
  const apiClient = useMemo(
    () =>
      axios.create({
        baseURL: env('NEXT_PUBLIC_BASE_URL'),
        withCredentials: true,
        xsrfCookieName: 'csrf-token',
        xsrfHeaderName: 'X-Csrf-Token',
        headers: {
          'Cache-Control': 'no-cache',
        },
      }),
    []
  );

  const apiFetch = useCallback(
    (url: string, method = 'GET'): Promise<Response> =>
      fetch(`${env('NEXT_PUBLIC_BASE_URL')}${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      }),
    []
  );

  return { apiClient, apiFetch };
}
