import { useEffect, useState } from 'react';

export function useClientRendering() {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
}
