'use client';

// Note that this hook is duplicated in huutokaupat.

import { useCallback, useEffect, useState } from 'react';

interface CookieConsent {
  readonly preferences: boolean;
  readonly statistics: boolean;
  readonly marketing: boolean;
  readonly youtube: boolean;
  readonly freshchat: boolean;
}

function getCookieConsentFromCookiebot(): CookieConsent {
  if (typeof window === 'undefined') {
    return {
      preferences: false,
      statistics: false,
      marketing: false,
      youtube: false,
      freshchat: false,
    };
  }

  return {
    preferences: window.Cookiebot?.consent.preferences ?? false,
    statistics: window.Cookiebot?.consent.statistics ?? false,
    marketing: window.Cookiebot?.consent.marketing ?? false,
    youtube: window.Cookiebot?.consent.preferences ?? false,
    freshchat: window.Cookiebot?.consent.preferences ?? false,
  };
}

interface UseCookieConsent {
  readonly cookiebotId: string | undefined;
  readonly cookieConsent: CookieConsent;
  readonly renewCookieConsent: () => void;
}

export function useCookieConsent(): UseCookieConsent {
  const [cookiebotId, setCookiebotId] = useState<string>();
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>(getCookieConsentFromCookiebot());

  useEffect(() => {
    const onConsentReady = () => {
      setCookiebotId(window.Cookiebot?.serial);
      setCookieConsent(getCookieConsentFromCookiebot());
    };

    window?.addEventListener('CookiebotOnConsentReady', onConsentReady);

    return () => {
      window?.removeEventListener('CookiebotOnConsentReady', onConsentReady);
    };
  }, []);

  const renewCookieConsent = useCallback(() => window?.Cookiebot?.renew(), []);

  return {
    cookiebotId,
    cookieConsent,
    renewCookieConsent,
  };
}
