import { env } from 'next-runtime-env';
import { FreshchatWidgetConfig } from '@/features/Freshchat/Freshchat.types';

const config: FreshchatWidgetConfig = {
  token: env('NEXT_PUBLIC_FRESHCHAT_TOKEN'),
  host: 'https://wchat.eu.freshchat.com',
  config: {
    headerProperty: {
      hideChatButton: true,
    },
    content: {
      placeholders: {
        search_field: 'Hae UKK:sta',
        reply_field: 'Kirjoita viestisi tähän...',
        csat_reply: 'Kirjoita kommenttisi tähän',
      },
      actions: {
        csat_yes: 'Kyllä',
        csat_no: 'Ei',
        push_notify_yes: 'Kyllä',
        push_notify_no: 'Ei',
        tab_faq: 'UKK',
        tab_chat: 'Chatti',
        csat_submit: 'Lähetä',
      },
      headers: {
        chat: 'Kirjoita viesti',
        chat_help: 'Hei, olemme täällä apunasi!',
        faq: 'UKK',
        faq_help: 'Selaa UKK:ta',
        faq_not_available: 'Ei artikkeleita',
        faq_search_not_available: 'Artikkeleita ei löytynyt haulla {{query}}',
        faq_useful: 'Oliko tästä artikkelista hyötyä?',
        faq_thankyou: 'Kiitos palautteestasi!',
        faq_message_us: 'Kirjoita meille',
        push_notification: 'Älä missaa viestejä! Salli ilmoitukset?',
        csat_question: 'Oliko meistä apua?',
        csat_yes_question: 'Miten arvioisit kokemustasi kanssamme?',
        csat_no_question: 'Miten voisimme mielestäsi kehittyä?',
        csat_thankyou: 'Kiitos palautteestasi!',
        csat_rate_here: 'Arviointisi',
        channel_response: {
          offline: 'Olemme pois chatista juuri nyt mutta jätä meille viesti.',
          online: {
            minutes: {
              one: 'Vastaamme muutamassa minuutissa.',
              more: 'Vastaamme alle {{time}} minuutissa.',
            },
            hours: {
              one: 'Vastaamme noin tunnin kuluessa',
              more: 'Vastaamme noin {{time}} tunnin kuluessa.',
            },
          },
        },
      },
    },
  },
};

export default config;
