'use client';

import NextLink from 'next/link';

/**
 * Link component from Next.js, default prefetch to false.
 */
export default function Link(props: React.ComponentProps<typeof NextLink>) {
  return <NextLink {...props} prefetch={props.prefetch ?? false} />;
}
